<template>
  <div class="about">
    <div class="banner-box">
      <div class="banner">
        <img :src="uri" />
        <span>MEET OUR TEAM</span>
      </div>
    </div>
    <div class="container">
      <div class="profile">
        <img
          class="cover"
          :src="infoData.detailesImgUrl"
        />
        <div v-if="infoData.name != 'Harry Hui' &&  infoData.name != 'William Chen'" class="card" v-html=" $store.state.lang=='zh'?infoData.descLangZh :infoData.desc"></div>
        <div v-else-if="infoData.name == 'Harry Hui'" class="card card2">
          <h2>{{$store.state.lang=='zh'?infoData.nameLangZh: infoData.name}}</h2>
          <p>{{ $store.state.lang=='zh'?infoData.postLangZh: infoData.post }}</p>
          <br />
          <div class="infos">
            <div>
              <h4>{{$store.state.lang=='zh'?'工作经验':'EXPERIENCE'}}</h4>
              <p>CP Equity China / Catterton Partners</p>
              <p>{{$store.state.lang=='zh'?'百事':'Pepsico'}}</p>
              <p>{{$store.state.lang=='zh'?'环球唱片':'Universal Music'}}</p>
              <p>MTV</p>
              <br />
              
            </div>
            <div>
              <h4>{{$store.state.lang=='zh'?'教育经历':'EDUCATION'}}</h4>
              <p>{{$store.state.lang=='zh'?'南加州大学':'MBA from USC'}}</p>
              <p>{{$store.state.lang=='zh'?'纽约州立大学学士':'BS - SUNY-Albany'}}</p>
            </div>
            <!-- <div>
              <h5>EDUCATION</h5>
              <p>MBA from USC</p>
              <p>BS - SUNY-Albany</p>
              <br />
              <h5>HONORS/ORGANIZATIONS</h5>
              <p>Global Leader of Tomorrow- World</p>
              <p>Economic Forum,</p>
              <p>Top 5 Businessmen of the Year Award- HK Chamber of Commerce</p>
              <p>YPO HK and Executive Committee of YPO Shanghai</p>
              <p>
                Top 10 Business Innovation Award- China Academy of Management
              </p>
              <p>
                Board of Directors for AAMA (Asia America Multi Technology
                Association)"
              </p>
            </div> -->
          </div>
          <div>
            <h4>{{$store.state.lang=='zh'?'达沃斯经济论坛-全球未来领袖':'HONORS/ORGANIZATIONS'}}</h4>
            <p>{{$store.state.lang=='zh'?'香港总商会年度五大商业领袖':'Global Leader of Tomorrow – WorldEconomic Forum'}}</p>
            <p v-if="$store.state.lang=='zh'">中国管理科学院十大商业创新奖</p>
            <p v-else>Top 5 Businessmen of the Year Award- HK Chamber of Commerce</p>
            <p v-if="$store.state.lang=='zh'">YPO（青年总裁协会）香港成员及上海执委会成员</p>
            <p v-else>YPO HK and Executive Committee of YPO Shanghai</p>
            <p v-if="$store.state.lang=='zh'">AAMA（亚美科技协会）董事会成员</p>
            <p v-else>Board of Directors for AAMA (Asia America Multi Technology Association)</p>
          </div>
        </div>
        

        <div v-else-if="infoData.name == 'William Chen'" class="card card2">
          <h2>{{$store.state.lang=='zh'?infoData.nameLangZh: infoData.name}}</h2>
          <p>{{ $store.state.lang=='zh'?infoData.postLangZh: infoData.post }}</p>
          <br />
          <div class="infos">
            <div>
              <h4 v-if=" $store.state.lang=='zh'">工作经验</h4>
              <h4 v-else>EXPERIENCE</h4>
              <p v-if=" $store.state.lang=='zh'">德同资本</p>
              <p v-else>DT Capital Partners</p>
              <p v-if=" $store.state.lang=='zh'">亚申科技</p>
              <p v-else>Accelergy Corp</p>
              
              <p v-if=" $store.state.lang=='zh'">OnePage，创始人和首席执行官（被Sybase收购）</p>
              <p v-else>OnePage / (Sybase)</p>
              <p v-if=" $store.state.lang=='zh'">Billpoint，创始人（被EBay收购）</p>
              <p v-else>Billpoint / (eBay)</p>
              <p v-if=" $store.state.lang=='zh'">Classifieds2000（被Excite收购）</p>
              <p v-else>Classifieds2000 / (Excite)</p>
              <p v-if=" $store.state.lang=='zh'">埃森哲管理咨询</p>
              <p v-else>Andersen Consulting Strategic Services</p>
              <p>IBM</p>
              <br />
              
            </div>
            <div>
              <h4 v-if=" $store.state.lang=='zh'">教育经历</h4>
              <h4 v-else>EDUCATION</h4>
              <p v-if=" $store.state.lang=='zh'">哈佛商学院MBA</p>
              <p v-else>MBA from Harvard Business School</p>
              <p v-if=" $store.state.lang=='zh'">佛罗里达州大学学士</p>
              <p v-else>BS from Univ. of Florida</p>
            </div>
          </div>
          <div>
            <h4 v-if=" $store.state.lang=='zh'">荣誉/组织</h4>
            <h4 v-else>HONORS/ORGANIZATIONS</h4>
            <p v-if=" $store.state.lang=='zh'">哈佛商学院院长顾问委员会</p>
            <p v-else>Harvard Business School Board of Dean’s Advisors 2014-2020</p>
            <p v-if=" $store.state.lang=='zh'">哈佛商学院校友董事（2009-2012）</p>
            <p v-else>Harvard Business School Alumni Board 2009-2012</p>
            <p v-if=" $store.state.lang=='zh'">亚斯本国际菁英组织亨利克隆奖（2006）</p>
            <p v-else>Aspen Institute Henry Crown Fellow 2006</p>
            <p v-if=" $store.state.lang=='zh'">佛罗里达州大学最佳企业家（2003）</p>
            <p v-else>Entrepreneur of the Year Univ. of Florida 2003</p>
            <p v-if=" $store.state.lang=='zh'">AAMA（亚杰商会）主席和总裁</p>
            <p v-else>Past Chairman and President of AAMA</p>
            <p v-if=" $store.state.lang=='zh'">YPO（青年总裁协会）上海分会主席 中文YPO（青年总裁协会）Gold 分会主席</p>
            <p v-else>YPO Gold Shanghai, Chapter Chair</p>
            <!-- <p v-if=" $store.state.lang=='zh'">AAMA(亚美科技协会)董事</p>
            <p v-else>Board of Directors for AAMA (Asia America Multi Technology Association)</p> -->
            <p v-if=" $store.state.lang=='zh'">WRI(世界资源研究所)董事</p>
            <p v-else>Board Director of World Resources Institute (WRI)</p>
          </div>
        </div>
      </div>

      <div v-html=" $store.state.lang=='zh'?infoData.contentLangZh :infoData.content"> </div>
      <!-- <p>
        Harry is a Founding Managing Partner of ClearVue Partners and has 30
        years of senior management and private equity experience. Harry was most
        previously Managing Director of CP Equity China, working with US based
        private equity firm Catterton Partners to bring global consumer brands
        to China and identifying investments for their global fund.
      </p>
      <br />
      <img
        style="width: 100%; display: block"
        src="https://cvp-new.oss-cn-hangzhou.aliyuncs.com/wp-content/uploads/2017/06/harry.jpg"
      />
      <br />
      <p>
        Prior to that, Harry was Chief Marketing Officer of Pepsico Greater
        China. Brands under his management included Pepsi Cola, Gatorade,
        Tropicana, Dole, 7UP, Mirinda, Starbucks, and Lipton tea. Harry was also
        VP of corporate relations for the Pepsico Investment Co. Ltd, where he
        was responsible for identifying investment and partnership opportunities
        for the company.
      </p>
      <br />
      <p>
        Prior to Pepsico, Harry had been at the forefront of the media/music
        industries in Asia for over 10 years, as Managing Director of MTV N.
        Asia, and as President of Universal Music SE Asia, where he led the
        companies through significant revenue growth and business model
        transformation.
      </p>
      <br />
      <p>
        During his tenure at Universal and Pepsi, Harry was responsible for
        creating the leading hit television shows "我型我SHOW" and Pepsi's
        "Battle of the Bands" for the China market.
      </p>
      <br />
      <p>
        Global Leader of Tomorrow- World Economic Forum, Top 5 Businessmen of
        the Year Award- HK Chamber of Commerce, YPO HK and Executive Committee
        of YPO Shanghai, Top 10 Business Innovation Award- China Academy of
        Management, Board of Directors for AAMA (Asia America Multi Technology
        Association).
      </p>
      <br />
      <p>MBA from USC, BS from SUNY-Albany.</p> -->
      <!-- <p class="harry">
        <span>SEE VIDEO PRESENTATIONS FOR HARRY C HUI</span>
      </p>
      <p class="video">
        VIDEO : <span>HARRY C HUI: MUSIC DREAMS EMPOWER CHINESE ARTISTS</span>
      </p>
      <p class="video">VIDEO : <span>财富人生 许智伟 秀场总裁</span></p>
      <p class="video">VIDEO : <span>许智伟 百事中国</span></p> -->
      <div class="video"></div>
    </div>
  </div>
</template>

<script>
import {getMenberInfo} from '@/apis'
export default {
  data() {
    return {
      uri: require("@/assets/images/WechatIMG172.jpg"),
      infoData: {},
    };
  },
  mounted(){
    this.getMenberInfo()
  },
  methods:{
    async getMenberInfo(){
      const {id} = this.$route.params
      const res = await getMenberInfo(id)
      res.data[0].content = res.data[0].content.replace(
        /georgia/g,
        'Arial'
      )
      res.data[0].desc = res.data[0].desc.replace(
        /georgia/g,
        'Arial'
      )
       res.data[0].content = res.data[0].content.replace(
        /georgia/g,
        'Arial'
      )
      res.data[0].desc = res.data[0].desc.replace(
        /24pt/g,
        '18px!important'
      )
      res.data[0].desc = res.data[0].desc.replace(
        /14pt/g,
        '12px!important'
      )
      res.data[0].desc = res.data[0].desc.replace(
        /18pt/g,
        '12px!important'
      )




      res.data[0].descLangZh = res.data[0].descLangZh.replace(
        /georgia/g,
        'Arial'
      )
      res.data[0].descLangZh = res.data[0].descLangZh.replace(
        /24pt/g,
        '18px!important'
      )
      res.data[0].descLangZh = res.data[0].descLangZh.replace(
        /14pt/g,
        '12px!important'
      )
      res.data[0].contentLangZh = res.data[0].contentLangZh.replace(
        /georgia/g,
        'Arial'
      )
      this.infoData = res.data[0]
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("../../assets/custom.scss");
.container {
  font-size: 18px;
}
.banner-box {
  padding-top: 106px;
  .banner {
    position: relative;
    img {
      // height: 330px;
      width: 100%;
    }
    span {
      position: absolute;
      color: #fff;
      font-size: 50px;
      z-index: 1;
      top: 50%;
      display: inline-block;
      transform: translate(0, -50%);
      right: 75px;
    }
  }
}

.profile {
  display: flex;
  margin-top: 106px;
  width: 100%;
  margin-bottom: 50px;
  font-size: 18px;
  img{
    width: 30%;
    height: auto;
  }
  .card {
    box-shadow: 0px 4px 9px 0px rgba(10, 2, 4, 0.28);
    padding: 20px 200px 20px 32px;
    min-width: 70%;
    line-height: 12px;
    span{
      span{
        background-color: #000;
        display: inline-block!important;
        line-height: 24px!important;
      }
      
    }
    .infos {
      display: flex;
      justify-content: space-between;
    }
  }
  .card2{
    
    line-height: 24px;
    p{
      font-size: 12px;
    }
    h4{
      font-size: 12px;
    }
    h2{
      font-size: 18px;
    }
  }
}

.harry {
  color: #fff;
  margin: 62px 0;
  span {
    background: rgb(12, 30, 95);
    padding: 25px 18px 30px 18px;
  }
}

.video {
  margin-bottom: 20px;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
